@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Lora:wght@400;500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway&display=swap');

* {
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

.app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem;
}

.app-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    color: white;
}

.app-central-text {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

.app-central-logo img {
    height: auto;
    width: 35vw;
    display: block;
    margin: 0 auto;
    animation: float 6s ease-in-out infinite;
}

.app-central-text {
    text-align: center;
    align-items: center;
    width: 70vw;
    margin: 0 auto;
}

.app-central-text h1 {
    font-size: 40px;
}

ion-button {
    margin: 15% 50% 15% 50%;
    transform: translate(-50%, -50%);
}

ion-card {
    --ion-background-color: #ffffff;
    text-align: center;
    width: 70%;
    height: auto;
    margin: 0 auto 10%;
}

.app-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 8px;
}

@media only screen and (min-width: 600px) {
    .app-central-logo img {
        height: auto;
        width: 10vw;
        display: block;
        margin: 0 auto;
    }

    .app-central-text {
        text-align: center;
        align-items: center;
        width: 50vw;
        margin: 0 auto;
    }

    ion-button {
        margin: 5% 50% 5% 50%;
        transform: translate(-50%, -50%);
    }

    ion-card {
        --ion-background-color: #ffffff;
        text-align: center;
        width: 15vw !important;
        height: auto;
        margin: 0 auto 5%;
    }

}