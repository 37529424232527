ion-card {
    width: 71% !important;
}








@media only screen and (min-width: 600px) { 
    ion-card {
        width: 30% !important;
    }
}
